import styled from "styled-components";

import { colors } from "styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';

export const Infos = styled.div`
  position: relative;

  /*padding: 22px;
  margin: 0 32px 50px;*/
  padding: 25px;
  margin: 0 0 50px;
  border-radius: 5px;
  text-align: center;

  background: ${colors.neutral};

  /*:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    left: -25px;
    top: 0;
    background-color: ${colors.black};
  }*/

  p {
    /*:first-of-type {
      text-transform: uppercase;
    }*/
    text-transform: uppercase;

    :not(:last-of-type) {
      margin-bottom: 25px;
    }
  }
`;

export const Chat = styled.div`
  margin: 0;
  text-align: center;

  a {
    text-decoration: underline;
    color: currentColor;

    :hover {
      color: ${colors.primary};
      cursor: pointer;
    }
  }
`

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

export const PSAccordion = styled(Accordion)`
   border: 1px solid #ccc;
   margin-top: 20px;
//   padding: 10px;
`;

export const PSAccordionSummary = styled(AccordionSummary)`
  cursor: pointer;
  text-align: center;
  padding: 10px 0;
`;

export const PSAccordionDetails = styled(AccordionDetails)`
  margin-top: 10px;
  padding: 10px;

  form {
    display: flex;
    gap: 15px;
    flex-direction: column;

    input[type='radio'] {
      margin-right: 5px;
    }

    #outrosMotivos {
      width: 100%;
      padding: 10px;
      font-size: 16px;
    }

    .small-text {
      font-size: 14px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 25px;
  flex-direction: column;
  margin-top: 25px;

  .errorMessage {
    color: red;
  }
`;