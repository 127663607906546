import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';

import { getOrder, checkOrder, doCancel } from 'services/api';
import { useAuthentication } from 'modules/authentication';
import { analyticsEvent } from 'modules/analytics';
import { chatEvent } from 'modules/chat';

import { Typography, Button, Loading } from "components";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import * as S from "./styles";
import OptionButton from "components/OptionsButton";
import { PSAccordion } from './styles';

function Options(props) {

  const { store, setOrderData } = useAuthentication();

  const [OSInfo, setOSInfo] = useState({
    error: 'Dados não encontrados.',
  });

  const [OSActions, setOSActions] = useState([]);
  const [hasOSActions, setHasOSActions] = useState(false);

  const [loadingData, setLoadingData] = useState(true);
  const [validatingActions, setValidatingActions] = useState(true);
  const [isCanceling, setIsCanceling] = useState(false);

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const outrosMotivosRef = useRef(null);
  const [error, setError] = useState('');
  const [isOutrosChecked, setIsOutrosChecked] = useState(false);

  const handleRadioClick = (e) => {
    setCancelReason(e.target.value);
    if(e.target.value === "Outros") {
      setOtherReason("");
      outrosMotivosRef.current.focus();
    } else {
      setOtherReason("");
      setIsOutrosChecked(false);
    }
  };

  async function getOrderData() {

    try {

      setLoadingData(true);

      const response = await getOrder(store.uuid);

      if (response.status !== 200 || !response.data.client) {
        throw response;
      }

      setOSInfo({ ...response.data });
      setOrderData({ ...response.data });

    } catch (error) {

      let message = '';

      if (error?.response?.data?.message) {
        message = error.response.data.message;
      }

      if (error?.response?.data?.type === '@data/not-found') {
        message = 'Sua sessão expirou.';
        setTimeout(() => document.location = process.env.REACT_APP_BASE_URL, 9000);
      }

      toast.error(message, {
        duration: 8000,
      });

      analyticsEvent({
        action: 'exception',
        description: message,
        fatal: false,
      });

    } finally {
      setLoadingData(false);
    }
  }

  async function checkOrderActions() {

    try {

      setValidatingActions(true);

      const response = await checkOrder(store.uuid);

      if (response.status !== 200 || !response.data) {
        throw response;
      }

      const actions = Object.entries(response.data).map(entry => {
        return {
          ...entry[1],
          type: entry[0],
        };
      });

      if (actions) {
        setOSActions(actions);
        setHasOSActions(
          actions.filter(action => action.enabled === true).length ? true : false,
        );
      }

    } catch (error) {
      //console.warn(`checkOrderActions:error`, {error});
    } finally {
      setValidatingActions(false);
    }

  }

  async function handleAction({ type, label, enabled, message }) {

    analyticsEvent({
      action: 'click',
      category: 'button',
      label: label,
    });

    if (!enabled) {

      toast(<span dangerouslySetInnerHTML={{ __html: message }}/>, {
        duration: 8000,
      });

      return;
    }

    setOrderData({ type });

    if (type === 'cancel') {

      // Alterna o estado do Accordion que passa a ser controlado pelo botão
      setIsAccordionOpen(prev => !prev);

    } else {

      props.push('scheduling');

    }

  }

  async function handleCancel(e) {

    e.preventDefault();

    if(!cancelReason) {
      setError('Por favor, selecione um motivo para o cancelamento.');
      return;
    }

    if (cancelReason === "Outros" && !otherReason) {
      setError('Por favor, informe o motivo ao selecionar "Outros".');
      return;
    }

    setError('');

    try {

      setIsCanceling(true);
      const response = await doCancel({uuid: store.uuid, reason: otherReason ? `${cancelReason}: ${otherReason}` : cancelReason});

      if (response.status !== 200 || !response.data.OS) {
        throw response;
      }

      setOrderData({
        novo_numero_os: '', // (response.data.OS !== store.numero_os) ? response.data.OS : ""
      });

      props.push('feedback');

    } catch (error) {

      let message = 'Ocorreu um erro. Tente novamente.';

      if (error?.response?.data?.message) {
        message = error.response.data.message;
      }

      if (error?.response?.data?.type === '@data/not-found') {
        message = 'Sua sessão expirou.';
        setTimeout(() => document.location = process.env.REACT_APP_BASE_URL, 9000);
      }

      toast.error(message, {
        duration: 8000,
      });

      analyticsEvent({
        action: 'exception',
        description: message,
        fatal: false,
      });

      setIsCanceling(false);

    } finally {
      // setIsCanceling(false);
      setIsAccordionOpen(false);
    }

  }

  function handleChat() {

    chatEvent({
      uuid: store.uuid,
      origin: 'Options',
    });

  }

  useEffect(() => {

    getOrderData();
    checkOrderActions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    analyticsEvent({
      action: 'login',
      category: 'engagement',
      label: store.numero_os,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>

      {/* <Typography.H2>{hasOSActions ?
      `Agora, siga com a sua opção:` : "Não há opções disponíveis nesse momento." }</Typography.H2> */}
      <Typography.H2>Agora, siga com a sua opção:</Typography.H2>

      {loadingData ?
        <Loading width="100%" height="200px"/> :
        <S.Infos>
          {OSInfo?.error ?
            <p>{OSInfo.error}</p> :
            <>
              <p>{OSInfo?.client?.service}</p>
              <p>{OSInfo?.client?.name}</p>
              <p>{OSInfo?.client?.address?.street}, {OSInfo?.client?.address?.number}
                <br/>{OSInfo?.client?.address?.neighborhood}, {OSInfo?.client?.address?.city}, {OSInfo?.client?.address?.state}
              </p>
            </>
          }
        </S.Infos>
      }

      {validatingActions ?
        <>
          <Loading width="100%" height="50px" style={{ marginTop: 25 }}/>
        </> :
        <S.Actions>
          {OSActions.map((action, index) => {
            const isTypeCancel = (action.type === 'cancel');
            return (
              <div key={index}>
                <OptionButton
                  key={index}
                  onClick={() => handleAction({
                    type: action.type,
                    label: action.label,
                    enabled: action.enabled,
                    message: action.message,
                  })}
                  disabled={isCanceling}
                >
                  {action.label}
                </OptionButton>
                {isTypeCancel && (
                  <div>
                    {isAccordionOpen && (
                      <S.PSAccordion expanded={isAccordionOpen}>
                        <S.PSAccordionSummary>Motivos para Cancelamento</S.PSAccordionSummary>
                        <S.PSAccordionDetails>
                          <form>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name="cancelReason"
                                  value="Desistiu / Já resolvido o problema."
                                  onChange={handleRadioClick}
                                />
                                Desistiu / Já resolvido o problema.
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name="cancelReason"
                                  value="Contratou serviço errado."
                                  onChange={handleRadioClick}
                                />
                                Contratou serviço errado.
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name="cancelReason"
                                  value="Indisponibilidade de reagendamento de data e horário."
                                  onChange={handleRadioClick}
                                />
                                Indisponibilidade de reagendamento de data e horário.
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name="cancelReason"
                                  value="Imprevisto, mas irá comprar novamente."
                                  onChange={handleRadioClick}
                                />
                                Imprevisto, mas irá comprar novamente.
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name="cancelReason"
                                  value="Imprevisto, não irá comprar novamente."
                                  onChange={handleRadioClick}
                                />
                                Imprevisto, não irá comprar novamente.
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name="cancelReason"
                                  value="Prefiro não informar."
                                  onChange={handleRadioClick}
                                />
                                Prefiro não informar.
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name="cancelReason"
                                  value="Outros"
                                  onChange={handleRadioClick}
                                  checked={isOutrosChecked}
                                />
                                Outros, gentileza escreva:
                                <input
                                  type="text"
                                  id="outrosMotivos"
                                  name="cancelReason"
                                  value={otherReason}
                                  onChange={(e) =>
                                    setOtherReason(e.target.value)}
                                  ref={outrosMotivosRef}
                                  maxLength={50}
                                  onFocus={() => {
                                    setCancelReason("Outros")
                                    setIsOutrosChecked(true)
                                  }
                                }
                                />
                                <span class="small-text">{`${50 - otherReason.length} caracteres restantes.`}</span>
                              </label>
                            </div>


                            <S.ButtonContainer>
                              {error && <p className="errorMessage" style={{ color: 'red'}}>{error}</p>}
                              <Button onClick={handleCancel}
                                      isLoading={isCanceling}
                                      disabled={false}>
                                Cancelar Serviço
                              </Button>
                              <Button onClick={() => {
                                setIsAccordionOpen(false);
                                setCancelReason('');
                                setError('');
                              }}
                                      disabled={isCanceling}>
                                Voltar
                              </Button>
                            </S.ButtonContainer>
                          </form>
                        </S.PSAccordionDetails>
                      </S.PSAccordion>
                    )}
                  </div>
                )}
              </div>
            );
          })}
          <S.Chat>Isso resolve o seu problema? Se não, <a href onClick={handleChat}>clique aqui</a> e fale com o nosso chat.</S.Chat>
        </S.Actions>
        // <>
        //   {hasOSActions ? OSActions.map((action, index) => {
        //     if(action.enabled) {
        //       return (
        //         <Button key={index} onClick={() => handleAction({type: action.type, label: action.label})} >
        //           {action.label}
        //         </Button>
        //       )
        //     } else {
        //       return ""
        //     }
        //   }) :
        //     <>
        //       {/* {!hasOSActions ? <S.Why>{OSActions.map(action => <li>{action.message}</li>)}</S.Why> : ''} */}
        //       <Button onClick={handleChat} >
        //         Chat online
        //       </Button>
        //     </>
        //   }

        //   {hasOSActions ? <S.Chat>Isso resolve o seu problema? Se não, <a href onClick={handleChat}>clique aqui</a> e fale com o nosso chat.</S.Chat> : ""}
        // </>
      }
    </>
  );
}

export default Options;