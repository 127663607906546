import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as S from "./styles";

function OptionButton(props) {
  return (
    <S.Container fill={props.fill} onClick={props.onClick} {...props}>
      {props.isLoading ? (
        <S.Loader>
          <CircularProgress size={23} color="inherit" />
        </S.Loader>
      ) : (
        props.children
      )}
    </S.Container>
  );
}

OptionButton.propTypes = {
  children: PropTypes.string,
  fill: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default OptionButton;
