import styled, { css } from "styled-components";
import { rem, lighten } from "polished";
import { space } from "styled-system";
import styledMap from "styled-map";

import { colors, fonts } from "styles";

export const Container = styled.button`
  all: unset;

  position: relative;

  ${space}

  padding: 20px 50px;
  height: auto;
  width: 100%;

  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: ${rem("13px")};
  font-weight: ${fonts.semibold};
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;

  -webkit-text-fill-color: ${styledMap("fill", {
    default: colors.white,
    outline: colors.primary,
  })};

  color: ${styledMap("fill", {
    default: colors.white,
    outline: colors.primary,
  })};

  background-color: ${styledMap("fill", {
    default: colors.primary,
    outline: colors.white,
  })};

  border: ${styledMap("fill", {
    default: "solid 2px transparent",
    outline: `solid 2px ${colors.primary}`,
  })};

  :focus {
    outline: none;
    box-shadow: none; /*0 0 0 4px ${colors.primary};*/
  }

  :disabled {
    background: rgba(0, 0, 0, 0.15) !important;
    cursor: not-allowed;
    color: #fff;
    border: none;
    -webkit-text-fill-color: #fff;

    :hover {
      background-color: rgba(0, 0, 0, 0.15);
      color: #fff;
      -webkit-text-fill-color: #fff;
    }

    ${({ fill }) =>
      fill &&
      css`
        border: transparent;
        color: #fff;
      `}
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.15);

      ${({ fill }) =>
        fill &&
        css`
          border: transparent;
          color: #fff;
        `}
    `}

  :hover,
  :focus {
    transition: 0.3s;

    background-color: ${styledMap("fill", {
      default: lighten(0.2, colors.primary),
      outline: lighten(0.2, colors.primary),
    })};

    -webkit-text-fill-color: ${styledMap("fill", {
      default: colors.white,
      outline: colors.white,
    })};

    color: ${styledMap("fill", {
      default: colors.white,
      outline: colors.white,
    })};

    border: ${styledMap("fill", {
      default: "solid 2px transparent",
      outline: `solid 2px ${lighten(0.2, colors.primary)}`,
    })};
  }
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;
